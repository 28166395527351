import { useState, useEffect, useContext } from "react";
import { ModalContext } from "./contexts/ModalContext.js";

import styles from "../styles/ChatMenuHistory.module.scss";

import infoCircle from "../img/info-circle.svg";
import trashIcon from "../img/trash.svg";

const ChatMenuHistory = ({
  menu,
  uniqueChatId,
  doRecreateConversation,
  toggleMenu,
}) => {
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setDisplayModal } = useContext(ModalContext);

  const fetchConversations = async () => {
    try {
      const response = await fetch("/api/conversations/get-conversations", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setConversations(data);
      }
    } catch (error) {
      console.error("Error getting new chat ID:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteConversation = async (conversation_id) => {
    try {
      const response = await fetch("/api/conversations/delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          conversation_id: conversation_id,
        }),
      });

      if (response.ok) {
        fetchConversations();
      }
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
  };

  useEffect(() => {
    if (menu.isExpanded) {
      fetchConversations();
    }
  }, [menu.isExpanded]);

  return (
    <div className={styles.container}>
      <div className={styles.topper}>
        <span className={styles.title}>History</span>
        <div className={styles.info}>
          <img src={infoCircle} alt="Info ikon" width={20} />
          <span className={styles.tooltip}>
            Du kan inaktivera konversationshistoriken genom att klicka på
            "Inaktivera historik" längst ner i denna meny.
          </span>
        </div>
      </div>
      {isLoading ? ( // Check if the data is still loading
        <p>Laddar konversationer...</p>
      ) : (
        <>
          <ul className={styles.history}>
            {Array.isArray(conversations) &&
              conversations.map((conversation, index) => (
                <div key={index}>
                  <li
                    key={index}
                    onClick={() => {
                      toggleMenu();
                      doRecreateConversation(conversation.conversation_id);
                    }}
                    className={
                      conversation.conversation_id === uniqueChatId &&
                      styles.active
                    }
                  >
                    {conversation.label}
                  </li>
                  <div>
                    <span>
                      {new Date(conversation.timestamp).toLocaleString(
                        "sv-SE",
                        {
                          day: "numeric",
                          month: "numeric",
                        }
                      )}
                    </span>
                    <img
                      src={trashIcon}
                      onClick={() =>
                        deleteConversation(conversation.conversation_id)
                      }
                      alt="Radera konversation"
                      width={20}
                    />
                  </div>
                </div>
              ))}
          </ul>
          <div className={styles.disableHistory}>
            <span
              onClick={() => setDisplayModal("DeactivateConversationHistory")}
            >
              Inaktivera historik
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatMenuHistory;
