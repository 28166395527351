import { assistantAzureRegexCheck } from "./assistantAzureRegexCheck.js";

export const handleAttachment = async (
  e,
  prompt,
  setPrompt,
  chat,
  setChat,
  addMessageBanner
) => {
  e.preventDefault();

  const maxFileSize = 10 * 1024 * 1024; // 10MB

  const attachmentsToUpload = Array.from(e.target.files).filter((file) => {
    if (file.size > maxFileSize) {
      alert(`File ${file.name} exceeds the maximum size.`);
      return false;
    }
    return true;
  });

  if (
    chat.attachments.length +
      prompt.attachmentsToUpload.length +
      attachmentsToUpload.length >
    5
  ) {
    // TODO: Better message
    addMessageBanner({
      position: "topMiddle",
      type: "failure",
      text: "Max 5 filer totalt. Max 10MB per fil.",
    });
    return;
  }

  if (attachmentsToUpload.length > 0 && assistantAzureRegexCheck(prompt.mode)) {
    setPrompt((prev) => {
      return {
        ...prev,
        attachmentsToUpload: [
          ...prev.attachmentsToUpload,
          ...attachmentsToUpload,
        ],
      };
    });

    try {
      const formData = new FormData();

      // Append uploaded files to FormData
      attachmentsToUpload.forEach((file) => {
        formData.append("prompt_files", file);
      });

      // Send user query to the server to get a response from the bot
      const response = await fetch("/api/attachments/upload", {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      if (response.ok) {
        const responseJson = await response.json();

        setChat((prev) => {
          return {
            ...prev,
            attachments: [...prev.attachments, ...responseJson],
          };
        });

        setPrompt((prev) => {
          return {
            ...prev,
            attachmentsToUpload: prev.attachmentsToUpload.filter(
              (file) => !attachmentsToUpload.includes(file)
            ),
          };
        });
      }
    } catch (error) {
      console.log("Unable to upload files", error);
    }
  }
};
